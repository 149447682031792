/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Player } from 'bitmovin-player'
import {
  PlaybackToggleOverlay, UIContainer, UIFactory, UIManager, UIConditionContext,
  SubtitleOverlay, SettingsPanel, SettingsPanelPage, SettingsPanelItem, VideoQualitySelectBox,
  PlaybackSpeedSelectBox, AudioQualitySelectBox, SubtitleListBox, AudioTrackListBox, ControlBar,
  Container, PlaybackTimeLabel, SeekBar, SeekBarLabel, PlaybackTimeLabelMode, PlaybackToggleButton,
  VolumeToggleButton, VolumeSlider, Spacer, PictureInPictureToggleButton, AirPlayToggleButton,
  CastToggleButton, VRToggleButton, SettingsToggleButton, FullscreenToggleButton, BufferingOverlay,
  CastStatusOverlay, TitleBar, RecommendationOverlay, Watermark, ErrorMessageOverlay,
} from 'bitmovin-player-ui'
import 'bitmovin-player/bitmovinplayer-ui.css'

const config = {
  key: 'DE5546BF-F956-4319-9324-BFAEDCB5117F',
  buffer: {
    video: {
      forwardduration: 30,
      backwardduration: 10,
    },
    audio: {
      forwardduration: 30,
      backwardduration: 10,
    },
  },
  playback: {
    autoplay: true,
    muted: true,
    playsinline: true,
  },
  ui: true,
  advertising: {},
  analytics: {
    key: 'BCAFD72F-8397-4212-AF7D-1AEE1939AF64',
  },
}

function modernUIWithSeparateAudioSubtitlesButtons() {
  const subtitleOverlay = new SubtitleOverlay()

  const settingsPanel = new SettingsPanel({
    components: [
      new SettingsPanelPage({
        components: [
          new SettingsPanelItem('Video Quality', new VideoQualitySelectBox()),
          new SettingsPanelItem('Speed', new PlaybackSpeedSelectBox()),
          new SettingsPanelItem('Audio Quality', new AudioQualitySelectBox()),
        ],
      }),
    ],
    hidden: true,
  })

  const subtitleListBox = new SubtitleListBox()
  const subtitleSettingsPanel = new SettingsPanel({
    components: [
      new SettingsPanelPage({
        components: [
          new SettingsPanelItem(null, subtitleListBox),
        ],
      }),
    ],
    hidden: true,
  })

  const audioTrackListBox = new AudioTrackListBox()
  const audioTrackSettingsPanel = new SettingsPanel({
    components: [
      new SettingsPanelPage({
        components: [
          new SettingsPanelItem(null, audioTrackListBox),
        ],
      }),
    ],
    hidden: true,
  })

  const controlBar = new ControlBar({
    components: [
      audioTrackSettingsPanel,
      subtitleSettingsPanel,
      settingsPanel,
      new Container({
        components: [
          new PlaybackTimeLabel(
            { timeLabelMode: PlaybackTimeLabelMode.CurrentTime, hideInLivePlayback: true },
          ),
          new SeekBar({ label: new SeekBarLabel() }),
          new PlaybackTimeLabel({ timeLabelMode: PlaybackTimeLabelMode.TotalTime, cssClasses: ['text-right'] }),
        ],
        cssClasses: ['controlbar-top'],
      }),
      new Container({
        components: [
          new VolumeToggleButton(),
          new VolumeSlider(),
          new Spacer(),
          new PictureInPictureToggleButton(),
          new AirPlayToggleButton(),
          new CastToggleButton(),
          new VRToggleButton(),
          new SettingsToggleButton({
            settingsPanel: audioTrackSettingsPanel,
            cssClass: 'ui-audiotracksettingstogglebutton',
          }),
          new SettingsToggleButton({
            settingsPanel: subtitleSettingsPanel,
            cssClass: 'ui-subtitlesettingstogglebutton',
          }),
          new SettingsToggleButton({ settingsPanel }),
          new FullscreenToggleButton(),
        ],
        cssClasses: ['controlbar-bottom'],
      }),
    ],
  })

  return new UIContainer({
    components: [
      subtitleOverlay,
      new BufferingOverlay(),
      new PlaybackToggleOverlay(),
      new CastStatusOverlay(),
      controlBar,
      new TitleBar({
        hidden: true,
      }),
      new RecommendationOverlay(),
      new Watermark({
        text: 'synamedia',
        url: 'https://synamedia.com/',
      }),
      new ErrorMessageOverlay(),
    ],
  })
}

const BundledPlayer = ({
  src, playerConfig, isLive, title, programId,
  initialMinute, poster, showAd, playedAd,
  alreadyUnmuted, setAlreadyUnmuted,
}) => {
  const defaultConfig = {
    ...config,
    ui: true,
    ...playerConfig,
    metadata: {
      title,
    },
  }

  const playerDiv = React.createRef()
  const [state, setState] = useState(
    { player: null },
  )
  const [player, setPlayer] = useState()

  const smallScreenSwitchWidth = 600

  function setAdBreak() {
    // Current playback position of the main content
    const currentTime = player.getCurrentTime()
    player.ads.schedule({
      tag: {
        url: 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear&correlator=', // MOCK AD
        type: 'vast',
      },
      id: 'Ad',
      position: currentTime.toString(),
    })
  }
  useEffect(() => {
    const bundlePlayer = new Player(playerDiv.current, defaultConfig)

    // Definition of the UI structure
    const liveUi = new UIContainer({
      components: [
        new PlaybackToggleOverlay(),
      ],
    })

    const myUiManager = new UIManager(bundlePlayer, [{
      ui: UIFactory.modernSmallScreenAdsUI(),
      condition: (context) => context.isMobile && context.documentWidth < smallScreenSwitchWidth
        && context.isAd && context.adRequiresUi,
    }, {
      ui: UIFactory.modernAdsUI(),
      condition: (context) => context.isAd && context.adRequiresUi,
    }, {
      ui: UIFactory.modernSmallScreenUI(),
      condition: (context) => context.isMobile && context.documentWidth < smallScreenSwitchWidth,
    }, {
      ui: liveUi,
      condition: () => isLive,
    }, {
      ui: modernUIWithSeparateAudioSubtitlesButtons(),
    },
    ], defaultConfig)
    setPlayer(bundlePlayer)
    return (() => {
      myUiManager.release()
      bundlePlayer.destroy() // DESTROYS PLAYER ON UNMOUNT
    })
  }, [])

  const unmutePlayer = () => {
    const handleInteraction = () => {
      if (player.isMuted()) {
        player.unmute()
      }
      window.removeEventListener('click', handleInteraction)
    }
    window.addEventListener('click', handleInteraction)
  }

  const changeSrc = (playerSource) => {
    try {
      player.load(playerSource).then(() => {
        if (!alreadyUnmuted) {
          unmutePlayer()
          setAlreadyUnmuted(true)
        }
      }).then(() => {
        setState({
          ...state,
          player,
        })
        console.log('Successfully loaded source')
      }, () => {
        console.log('Error while loading source')
      })

      if (showAd) {
        player.on('adclicked', (event) => {
          player.play() // Remsume player on ad clicked event
        })
        player.on('timechanged', (event) => {
          // SET ADS EVERY X SECONDS AFTER STARTING PLAYBACK
          if (Math.floor(event.time)
            === Math.floor(initialMinute * 60) + 10) {
            playedAd(programId)
            setAdBreak()
          }
        })
      }
    } catch (e) {
      /**
       * Nothing to be done, since catch is trying to add stream to
       * past video player (already destroyed)
       */
    }
  }
  useEffect(() => {
    const removePlay = document.getElementsByClassName('bmpui-ui-hugeplaybacktogglebutton')
    if (removePlay.length > 0) removePlay[0].parentNode.removeChild(removePlay[0])
  })

  useEffect(() => {
    if (player && src) {
      changeSrc({
        options: {
          startOffset: initialMinute * 60, // seconds
        },
        hls: src,
        title,
        poster,
      })
    }
  }, [player, src])

  return (
    <div
      id="player"
      ref={playerDiv}
    />
  )
}

BundledPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  playerConfig: PropTypes.objectOf(PropTypes.any),
  initialMinute: PropTypes.number,
  isLive: PropTypes.bool,
  title: PropTypes.string,
  poster: PropTypes.string,
  showAd: PropTypes.bool,
  playedAd: PropTypes.func,
  programId: PropTypes.string,
  alreadyUnmuted: PropTypes.bool,
  setAlreadyUnmuted: PropTypes.func,
}

BundledPlayer.defaultProps = {
  playerConfig: {},
  initialMinute: 0,
  isLive: false,
  title: '',
  poster: '',
  showAd: true,
  programId: '',
  playedAd: () => { },
  alreadyUnmuted: true,
  setAlreadyUnmuted: () => { },
}

export default BundledPlayer
